<template>
  <div class="flex justify-between w-full gap-4">
    <ui-button
      @click="useAuthProvider('google')"
      :button-type="ButtonType.Outline"
      :size="ButtonSize.Bigger"
      :font-size="ButtonFontSize.Small"
      rounded
    >
      <template #before_icon>
        <img
          src="@/assets/images/social/google.svg"
          alt="Sign-in with Google"
        />
      </template>
      {{ signUp ? "Sign-up with Google" : "Sign-in with Google" }}
    </ui-button>

    <ui-button
      @click="useAuthProvider('microsoft')"
      :button-type="ButtonType.Outline"
      :size="ButtonSize.Bigger"
      :font-size="ButtonFontSize.Small"
      rounded
    >
      <template #before_icon>
        <img
          src="@/assets/images/social/microsoft.svg"
          alt="Sign-in with Microsoft"
        />
      </template>
      {{ signUp ? "Sign-up with Microsoft" : "Sign-in with Microsoft" }}
    </ui-button>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton";
import { ButtonType, ButtonSize, ButtonFontSize } from "@/enums/button";
import {
  triggerError,
  getTokenViaSocialAuth,
} from "@/services/api/social-auth-api";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { Providers } from "universal-social-auth";

import { inject } from "vue";

export default {
  components: {
    UiButton,
  },
  props: {
    signUp: {
      type: Boolean,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const socialAuth = inject("socialAuth");
    const socialAuthProviders = inject("socialAuthProviders");

    const token = route.query.token || null;

    const useAuthProvider = function (provider) {
      const extendProviderData = Object.assign({}, Providers[provider]);

      if (token)
        localStorage.setItem("token-for-password-creation", route.query.token);

      socialAuth
        .authenticate(provider, extendProviderData)
        .then((response) => {
          if (response.error) {
            triggerError(response.error_message);
            return;
          }

          if (response.code) {
            getTokenViaSocialAuth(
              {
                router,
                route,
                store,
              },
              {
                provider,
                code: response.code,
                socialAuthProviders,
                token,
              }
            );
          }
        })
        .catch((err) => {
          triggerError(router, err);
        });
    };

    return {
      useAuthProvider,
      ButtonType,
      ButtonSize,
      ButtonFontSize,
    };
  },
};
</script>
