<template>
  <div class="flex items-center flex-col pt-13 pb-7 relative">
    <p class="auth-error" v-if="error.length">{{ error }}</p>
    <h1 class="auth-form__title"><b>Sign</b> in</h1>

    <Form @submit="onSubmit" :validation-schema="schema" class="auth-form">
      <ui-validation-input
        name="username"
        label="Email"
        class="auth-form__field"
      />
      <ui-validation-input
        name="password"
        label="Password"
        class="auth-form__field"
        type="password"
      />

      <p class="text-left w-full mb-4">
        <router-link
          class="text-xs"
          :to="{ name: ROUTER_NAMES.FORGOT_PASSWORD }"
          >Forgot pass?
        </router-link>
      </p>
      <ui-button rounded class="w-auth-form" :button-type="ButtonType.Blue"
        >Sign in
      </ui-button>
    </Form>

    <div class="w-auth-form">
      <div class="flex items-center justify-around w-full pt-3.5 pb-2.5">
        <div class="h-px bg-gray-300 w-full"></div>
        <span class="px-9 text-sm">Or</span>
        <div class="h-px bg-gray-300 w-full"></div>
      </div>

      <social-auth-buttons />
    </div>

    <p class="mt-10 text-xs font-medium">
      No account?
      <span
        class="text-red font-bold cursor-pointer hover:underline"
        @click="signUp"
      >
        Sign up
      </span>
    </p>
    <p class="mt-3 text-center text-gray text-xs">
      By clicking “Sign in” I agree to the
      <router-link
        target="_blank"
        :to="{
          name: ROUTER_NAMES.PRIVACY,
          query: { from: ROUTER_NAMES.SIGN_IN },
        }"
        >“Privacy Policy <br />
        & Terms and Conditions”
      </router-link>
    </p>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import UiValidationInput from "@/components/ui/UiValidationInput.vue";
import http from "@/services/http";
import { HOME_PAGE_BY_ROLE, ROUTER_NAMES } from "@/router/router.enum";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import SocialAuthButtons from "@/modules/auth/components/SocialAuthButtons.vue";
import { ButtonType } from "@/enums/button";

export default {
  components: {
    Form,
    UiValidationInput,
    UiButton,
    SocialAuthButtons,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const error = ref("");
    const store = useStore();
    const schema = Yup.object().shape({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
    });

    const signUp = () => {
      router.push({ name: ROUTER_NAMES.SIGN_UP });
    };

    const onSubmit = async (values) => {
      error.value = "";
      try {
        await http.fetchTokensByCredentials(values);
        const user = await store.dispatch("fetchUser");
        if (route.query.from && route.query.from.includes("supplier")) {
          router.push({ path: route.query.from });
        } else {
          router.push({ name: HOME_PAGE_BY_ROLE[user.role] });
        }
      } catch (err) {
        let errorText = "";
        if (err.non_field_errors) {
          errorText = err.non_field_errors[0];
        } else if (err.detail) {
          errorText = err.detail;
        } else {
          errorText = err;
        }
        error.value = errorText;
      }
    };

    return {
      error,
      ROUTER_NAMES,
      onSubmit,
      schema,
      signUp,
      ButtonType,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth-form {
  @apply w-auth-form;
  @apply flex flex-col items-center;
  @apply mx-auto;
  @apply h-full;

  &__title {
    @apply text-4xl;
    @apply mb-6;
  }

  &__field {
    @apply w-full;
    @apply mb-5;
  }
}

.auth-error {
  @apply absolute top-4;
  @apply text-red text-sm font-semibold;
}
</style>
